<template>

    <!-- If loaded -->
    <template v-if="loaded">

        <!-- DEFINED State -->
        <div class="card my-3" v-if="localInfra.status === InfraStatusCode.DEFINED">
            <div class="card-body">
                <h6 class="card-text text-center">
                    <i class="bi bi-truck" style="font-size: 3em;"></i><br>
                    {{ localInfra.name }}
                    <br>
                    <small class="text-muted">Commande passée, en attente de traitement</small>
                    <br>
                    <br>
                </h6>
            </div>
        </div>

        <!-- PENDING State -->
        <div class="card my-3" v-if="localInfra.status === InfraStatusCode.PENDING">
            <div class="card-body">
                <h6 class="card-text text-center">
                    <i class="bi bi-building" style="font-size: 3em;"></i><br>
                    {{ localInfra.name }}
                    <br>
                    <small class="text-muted">En cours de construction...</small>
                    <br>
                    <br>
                    <span class="spinner-grow spinner-grow-sm me-2"></span>
                    <span class="spinner-grow spinner-grow-sm me-2"></span>
                    <span class="spinner-grow spinner-grow-sm me-2"></span>
                </h6>
            </div>
        </div>

        <!-- STARTING State -->
        <div class="card my-3" v-if="localInfra.status === InfraStatusCode.STARTING">
            <div class="card-body">
                <h6 class="card-text text-center">
                    <i class="bi bi-play-circle text-success" style="font-size: 3em;"></i><br>
                    {{ localInfra.name }}
                    <br>
                    <small class="text-muted">En cours de démarrage (peux prendre quelques minutes...)</small>
                    <br>
                    <br>
                    <span class="spinner-grow spinner-grow-sm me-2"></span>
                    <span class="spinner-grow spinner-grow-sm me-2"></span>
                    <span class="spinner-grow spinner-grow-sm me-2"></span>
                </h6>
            </div>
        </div>

        <!-- STARTED State -->
        <div class="card my-3" v-if="localInfra.status === InfraStatusCode.STARTED">
            <div class="card-header">
                <h6 class="card-text">
                    <i class="bi bi-building me-2"></i>
                    <i class="bi bi-arrow-up-circle-fill me-2 text-success"></i>{{ infra.name }} <small class="text-muted">{{ localInfra._id }} -  démarrée</small>
                </h6>
            </div>
            <div class="card-body">

                <div class="container-fluid">
                    <div class="row justify-content-md-center">
                        
                        <div class="col">
                            <a :href="getInfraAttributes().webUrl" target="_blank" style="text-decoration: none;">
                                <div class="card shadow shadow-sm card">
                                    <div class="card-body text-center ">
                                        <em><small>Routeur (UTM)</small></em><br>
                                        <i class="bi bi-router" style="font-size: 2em;"></i>
                                        <br>
                                        <strong v-if="localExposedPorts['webgui']">{{ getInfraAttributes().eip }}</strong>
                                        <span v-else class="spinner spinner-border spinner-border-sm"></span>
                                    </div>
                                </div>    
                            </a>
                        </div>

                        <div class="col">
                            <div class="card shadow shadow-sm">
                                <div class="card-body text-center">
                                    <em><small>Réseau(x)</small></em><br>
                                    <i class="bi bi-diagram-3" style="font-size: 2em;"></i>
                                    <br>
                                    <strong>{{ getInfraAttributes().nbSubnets }}</strong>
                                </div>
                            </div>    
                        </div>

                        <div class="col">
                            <div class="card shadow shadow-sm">
                                <div class="card-body text-center">
                                    <em><small>Serveur(s)</small></em><br>
                                    <i class="bi bi-motherboard" style="font-size: 2em;"></i>
                                    <br>
                                    <strong>{{ getInfraAttributes().nbInstances }}</strong>
                                </div>
                            </div>    
                        </div>
                        
                        <div class="col">
                            <div class="card shadow shadow-sm card">
                                <div class="card-body text-center">
                                    <em><small>Stockage</small></em><br>
                                    <i class="bi bi-device-hdd" style="font-size: 2em;"></i>
                                    <br>
                                    <strong>{{ getInfraAttributes().nbEbsReserved }} Go</strong>
                                </div>
                            </div>    
                        </div>

                    </div>
                </div>

            </div>
            <div class="card-footer text-end">
                
                <a :href="'/api/infras/'+infra._id+'/rdpfiles/'+rdpFile.name" class="btn btn-outline-dark btn-sm me-2" :class="{'disabled': !localExposedPorts[rdpFile.name]}" v-for="rdpFile in infra.exposedPorts.filter( i => i.type ==='rdp' )" :key="rdpFile.name">
                    <i class="bi bi-microsoft me-2"></i>{{rdpFile.name}} <i class="bi mb-2" :class="{'bi-arrow-up-circle-fill text-success' : localExposedPorts[rdpFile.name], 'bi-arrow-down-circle' : !localExposedPorts[rdpFile.name]}"></i>
                </a>
                <a href="#" class="btn btn-primary btn-sm me-2 disabled" v-for="sshFile in infra.exposedPorts.filter( i => i.type ==='ssh' )" :key="sshFile.name">
                    <i class="bi bi-terminal-fill me-2"></i>{{sshFile.name}}
                </a>
                <button class="btn btn-secondary btn-sm me-2" @click="stopInfra()">
                    <i class="bi bi-stop-circle me-2"></i>Arrêter
                </button>
                <button class="btn btn-danger btn-sm" @click.prevent="toggleModal()">
                    <i class="bi bi-trash me-2"></i>Supprimer
                </button>
            </div>
        </div>

        <!-- STOPPING State -->
        <div class="card my-3" v-if="localInfra.status === InfraStatusCode.STOPPING">
            <div class="card-body">
                <h6 class="card-text text-center">
                    <i class="bi bi-stop-circle-fill text-warning" style="font-size: 3em;"></i><br>
                    {{ localInfra.name }}
                    <br>
                    <small class="text-muted">En cours d'arrêt (vous pouvez vous déconnecter)</small>
                    <br>
                    <br>
                    <span class="spinner-grow spinner-grow-sm me-2"></span>
                    <span class="spinner-grow spinner-grow-sm me-2"></span>
                    <span class="spinner-grow spinner-grow-sm me-2"></span>
                </h6>
            </div>
        </div>

        <!-- STOPPED State -->
        <div class="card my-3" v-if="localInfra.status === InfraStatusCode.STOPPED">
            <div class="card-body">
                <h6 class="card-text">
                    <span class="align-middle">
                        <i class="bi bi-building me-2"></i>
                        <i class="bi bi-arrow-down-circle-fill me-2 text-muted"></i>
                        {{ infra.name }} <small class="text-muted">{{ localInfra._id }} -  à l'arrêt</small>
                    </span>
                    <div class="float-end">
                        <div class="btn-group" role="group" aria-label="Basic example">
                            <button class="btn btn-secondary btn-sm" @click="startInfra()">
                                <i class="bi bi-play-circle"></i>
                            </button>
                            <button class="btn btn-danger btn-sm" @click.prevent="toggleModal()">
                                <i class="bi bi-trash"></i>
                            </button>
                        </div>
                    </div>
                </h6>
                
            </div>
        </div>

        <!-- DELETING State -->
        <div class="card my-3" v-if="localInfra.status === InfraStatusCode.DELETING">
            <div class="card-body">
                <h6 class="card-text text-center">
                    <i class="bi bi-trash text-danger" style="font-size: 3em;"></i><br>
                    {{ localInfra.name }}
                    <br>
                    <small class="text-muted">En cours de suppression</small>
                    <br>
                    <br>
                    <span class="spinner-grow spinner-grow-sm me-2"></span>
                    <span class="spinner-grow spinner-grow-sm me-2"></span>
                    <span class="spinner-grow spinner-grow-sm me-2"></span>
                </h6>
            </div>
        </div>
        
        <!-- UPDATING State -->
        <div class="card my-3" v-if="localInfra.status === InfraStatusCode.UPDATING">
            <div class="card-body">
                <h6 class="card-text text-center">
                    <!-- <i class="bi bi-gear" style="font-size: 3em;"></i><br> -->
                    <img src="/assets/infra_updating.gif" alt="" style="height:96px;" class="rounded mx-auto d-block">
                    <br>
                    {{ localInfra.name }}
                    <br>
                    <small class="text-muted">En cours de mise à niveau</small>
                    <br>
                    <!-- <br>
                    <span class="spinner-grow spinner-grow-sm me-2"></span>
                    <span class="spinner-grow spinner-grow-sm me-2"></span>
                    <span class="spinner-grow spinner-grow-sm me-2"></span> -->
                </h6>
            </div>
        </div>

        <!-- LOCKED State -->
        <div class="card my-3" v-if="(localInfra.status === InfraStatusCode.LOCKED)">
            <div class="card-body">
                <h6 class="card-text text-center">
                    <i class="bi bi-lock text-danger" style="font-size: 3em;"></i><br>
                    {{ localInfra.name }}
                    <br>
                    <small class="text-muted">Vérouillée : Contactez votre administrateur système</small>
                    <br>
                    <br>
                    <span class="spinner-grow spinner-grow-sm me-2"></span>
                    <span class="spinner-grow spinner-grow-sm me-2"></span>
                    <span class="spinner-grow spinner-grow-sm me-2"></span>
                </h6>
            </div>
        </div>

        <!-- Modal for deletion conformation -->
        <div class="modal fade" tabindex="-1" :id="'deleteConfirm_'+localInfra._id" aria-hidden="true" v-if="localInfra">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Confirmation</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                <div class="modal-body">
                    <p class="text-justify">Vous vous apprêtez à supprimer votre infra nommée <code>{{localInfra.name}}</code>. Cette action entraînera la suppression de toutes les ressources associés, y compris les données.</p>
                    <p class="text-justify">Veuillez donc confirmer votre choix en recopiant son identifiant <code>{{localInfra._id}}</code> suivis de deux fois <code>oui-je-le-veux</code>.</p>
                    <div class="mb-3">
                        <label for="" class="form-label">Confirmez</label>
                        <input type="text" class="form-control text-danger" v-model="IreallyReallyWantItString">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                    <button type="button" class="btn btn-danger" @click.prevent="destroyInfra()" :disabled="(!IreallyReallyWantItCheck)">Supprimer</button>
                </div>
                </div>
            </div>
        </div>

    </template>

    <!-- If not finished to load  -->
    <template v-else>
        <div class="card my-3">
            <div class="card-body">
                <h6 class="card-text text-center">
                    <span class="spinner-grow spinner-grow-sm me-2"></span>
                    <span class="spinner-grow spinner-grow-sm me-2"></span>
                    <span class="spinner-grow spinner-grow-sm me-2"></span>
                </h6>
            </div>
        </div>
    </template>

</template>

<script setup>

    import axios from 'axios';
    import { defineProps, defineEmits, onMounted, ref, computed } from 'vue';
    import Modal from "bootstrap/js/dist/modal";

    let localInfra = ref(null);
    let loaded = ref(false);
    let localExposedPorts = ref({});
    let timeoutPointer = null;

    let IreallyReallyWantItString = ref("");
    let IreallyReallyWantItCheck = computed( () => {
        return ( localInfra.value._id + " oui-je-le-veux oui-je-le-veux" ) === IreallyReallyWantItString.value;
    })

    let InfraStatusCode = {
        "DEFINED": 0,
        "PENDING": 1,
        "STARTING": 2,
        "STARTED": 3,
        "STOPPING": 4,
        "STOPPED": 5,
        "DELETING": 6,
        "UPDATING": 7,
        "LOCKED": 8,
    }

    const props = defineProps([
        "infra"
    ]);

    const emit = defineEmits([
        "destroyed"
    ])


    onMounted( () => {
        load();
        checkPorts();
    })

    function load(){

        axios.get("/api/users/me/infras/" + props.infra._id)
            .then( answer => {
                localInfra.value = answer.data.infraResponse;
                
                if( [InfraStatusCode.DEFINED , InfraStatusCode.PENDING, InfraStatusCode.STOPPING, InfraStatusCode.STARTING, InfraStatusCode.DELETING].includes(localInfra.value.status) ){
                    setTimeout( () => load(), ( 1 * 60000) ); // Every minute
                }
                loaded.value = true;
                
            })
            .catch( e  => {
                if(e.response && e.response.status === 404){
                    loaded.value = false;
                    emit("destroyed");
                }
                else{
                    alert(e);
                }
            })
    }

    // Start the infra
    function startInfra(){
        axios.post("/api/users/me/infras/" + props.infra._id + "/start")
            .then( answer => {
                clearTimeout(timeoutPointer);
                load();
            })
            .catch( e  => {
                alert(e);
            })
    }

    // Stop the infra
    function stopInfra(){
        axios.post("/api/users/me/infras/" + props.infra._id + "/stop")
            .then( answer => {
                clearTimeout(timeoutPointer);
                load();
            })
            .catch( e  => {
                alert(e);
            })
    }

    function toggleModal(){
        let myModal = Modal.getOrCreateInstance(document.querySelector("#deleteConfirm_"+localInfra.value._id));
        myModal.toggle();
    }

    // Destroy the infra
    function destroyInfra(){
        axios.delete("/api/users/me/infras/" + props.infra._id)
            .then( answer => {
                let myModal = Modal.getOrCreateInstance(document.querySelector("#deleteConfirm_"+localInfra.value._id));
                myModal.toggle();
                clearTimeout(timeoutPointer);
                load(); //--> Need to fix the backdrop bug
            })
            .catch( e  => {
                alert(e);
            })
    }

    function getInfraAttributes(){

        const resources = localInfra.value.states.resources;
        const exposedPorts = localInfra.value.exposedPorts;

        const eip = resources.filter( r => r.name === "natInstanceEip")[0].instances[0].attributes.public_ip;
        const webPort = exposedPorts.filter( e => e.name === "webgui")[0].port;
        const webUrl = `https://${eip}:${webPort}`;
    
        const nbInstances = resources.filter( r => r.type === "aws_instance").length;

        const nbEbsReserved = resources.filter( r => r.type === "aws_instance").reduce( (total, currentValue) =>{
            
            let instance = currentValue.instances[0];
            total += instance.attributes.root_block_device[0].volume_size;

            return total;
            
        }, 0);

        const nbSubnets = resources.filter( r => r.type === "aws_subnet" ).length;

        return {
            eip,
            webUrl,
            nbInstances,
            nbEbsReserved,
            nbSubnets
        }
    }

    function checkPorts(){
        if(timeoutPointer !== null) clearTimeout(timeoutPointer);
        
        props.infra.exposedPorts.forEach( port => {
            if(props.infra.status === InfraStatusCode.STARTED && !localExposedPorts.value[port.name]){
                axios.get("/api/infras/" + props.infra._id + "/checkport/" + port.name)
                    .then( answer => {
                        localExposedPorts.value[port.name] = answer.data.infraCheckPortResponse;
                        if(!answer.data.infraCheckPortResponse){
                            timeoutPointer = setTimeout( () => checkPorts() , 30000);
                        }
                    })
                    .catch( e => {
                        alert(e.response.data);
                    })
                // Disable because actually we got a hell of setTimeout :-S need to be debugged
                // timeoutPointer = setTimeout( () => checkPorts() , 30000)
            }
        })

    }

</script>

<style>
</style>