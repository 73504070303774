<template>
    <div class="modal fade" tabindex="-1" id="InfraAddModal" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close" @click.prevent="close()" aria-label="Close"></button>
            </div>
            <div class="modal-body" v-if="loaded">

                <div class="alert alert-secondary mb-3">
                    <h5 class="card-title text-center lead">
                        <i class="bi bi-folder-plus" style="font-size: 3em;"></i>
                        <br>
                        <strong>Préparons votre infra</strong>
                    </h5>
                </div>

                <form  class="form">
                    <div class="mb-3">
                        <label class="form-label">Nom</label>
                        <input type="text" class="form-control" placeholder="Nommez votre infra" v-model="name">
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Modèle de base</label>
                        <select class="form-select" v-model="details">
                            <option v-for="terra in terras" :value="terra" :key="terra._id">{{terra.name}}</option>
                        </select>
                    </div>
                    <div class="mb-3" v-if="details">
                        <div class="card">
                            <div class="card-body bg-light">
                                <table class="table table-hover form-text">
                                    <tbody>
                                        <tr>
                                            <th colspan="2">Description</th>
                                        </tr>
                                        <tr>
                                            <td colspan="2">{{details.description}}</td>
                                        </tr>
                                        <tr>
                                            <th>Nb Machine(s) préconfigurée(s)</th>
                                            <td>{{ Object.keys(details.model.resource.aws_instance).length }}</td>
                                        </tr>

                                        <tr>
                                            <th>Bloc(s) d'infra restant(s)</th>
                                            <td>{{ userInfo.maxInfras - infrasCount }}</td>
                                        </tr>
                                        <tr :class="{'text-danger': (userInfo.maxInfras - infrasCount -1 === 0)}">
                                            <th>Bloc(s) d'infra restant(s) après</th>
                                            <td>{{ userInfo.maxInfras - infrasCount -1 }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
            <div class="modal-body" v-else>
                <div class="card-text text-center">
                    <span class="spinner-border spinner-border-sm"></span>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click.prevent="close()">Annuler</button>
                <button type="button" class="btn btn-primary" @click.prevent="add()" :class="{disabled: details === null || name === ''}" id="btnAddInfra">
                    <span class="spinner-border spinner-border-sm me-2" v-if="startBuilding"></span>
                    <i class="bi bi-building me-2"></i>Lancer la construction
                </button>
            </div>
            </div>
        </div>

    </div>

    
    <ErrorBox modal-id="InfraAddErrorBox"
        :show="error"
        :message="error" 
        @close="errorClose()" />
        
</template>

<script setup>

    import { ref, defineProps, defineEmits, onMounted } from 'vue';
    import Modal from "bootstrap/js/dist/modal";
    import axios from 'axios';
    import $ from "jquery";
    import ErrorBox from '../../admin/components/generics/ErrorBox.vue';

    defineProps([
        "userInfo",
        "infrasCount",
        "show"
    ])

    let emit = defineEmits([
        "close",
        "added",
        "error"
    ])

    let loaded = ref(false);
    let terras = ref([]);
    let details = ref(null);
    let startBuilding = ref(false);
    let name = ref("");
    let error = ref(false);

    onMounted( () => {
        var myModal = Modal.getOrCreateInstance(document.querySelector('#InfraAddModal'));
        myModal.show();
        load();
    })

    function load(){
        
        startBuilding.value = false;
        terras.value = [];
        details.value = null;

        axios.get("/api/terras")            
            .then( answer => {
                terras.value = answer.data;
                loaded.value = true;
            })
            .catch( e => {
                error.value = (e.response.data.message) ? e.response.data.message : e;
            })
    }

    function close(){
        var myModal = Modal.getOrCreateInstance(document.querySelector('#InfraAddModal'));
        myModal.hide();
        emit("close");
    }

    function add(){
        $("#btnAddInfra").toggleClass("disabled");
        startBuilding.value = true;
        axios.post("/api/infras", {terra_id: details.value._id, infra_name: name.value})
            .then( answer => {
                close();
            })
            .catch( e => {
                var myModal = Modal.getOrCreateInstance(document.querySelector('#InfraAddModal'));
                myModal.hide();
                error.value = (e.response.data.message) ? e.response.data.message : e;
            })
    }

    function errorClose(){
        error.value = false;
        var myModal = Modal.getOrCreateInstance(document.querySelector('#InfraAddModal'));
        startBuilding.value = false;
        load();
        myModal.show();
        //setTimeout( () => close(), 1000);
    }


</script>