<template>

    <div class="container" v-if="!loaded">
        <div class="row">
            <div class="alert alert-dark my-3">
                <div class="spinner-border spinner-border-sm me-2" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <i><small>Chargement en cours...</small></i>
            </div>
        </div>
    </div>
    
    <div class="container" v-else>

        <!-- Display general informations -->
        <div class="row">
            <div class="col">
                <div class="card my-3">
                    <div class="card-header">
                        <h5 class="card-text"><i class="bi bi-activity me-2"></i>Espace de gestion de vos infrastructures</h5>
                    </div>
                    <div class="card-body">
                        <p class="card-text">Ici vous pouvez créer et détruire vos infrastructures. Vous avez actuellement un profil vous permettant de bénéficier d'un maximum de {{userInfo.maxInfras}} infrastructure(s)</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Display empty infra block to invite user to create new one -->
        <div class="row" v-if="(userInfo.maxInfras - infras.length) > 0">
            <div class="col">
                <div role="button" class="card text-center text-white my-3" @click.prevent="showAddInfra=true" style="border-style: dashed; border-width: 3px; border-color: white; background-color: transparent;">
                    <div class="card-body">
                        <h5 class="card-title">Emplacement(s) restant(s) : {{ userInfo.maxInfras - infras.length }}</h5>
                        <p class="card-text"><small><em>Vous pouvez créer une nouvelle infra en cliquant ici</em></small></p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Display Infras informations -->
        <div class="row" v-for="infra in infras" :key="infra._id">
            <div class="col">
                <describe-infra-vue :infra="infra" @destroyed="removeDescribeInfraItem(infra)"/>
            </div>
        </div>

        <!-- Modals -->
        <infra-add-vue v-if="showAddInfra"
            :user-info="userInfo"
            :infras-count="infras.length"
            @error="infraAddError(err)"
            @close="closeInfraAdd"/>

    </div>

</template>

<script setup>

    import axios from 'axios';
    import { onMounted, defineProps, defineEmits, ref } from 'vue';

    import InfraAddVue from './modals/InfraAdd.vue';
    import DescribeInfraVue from './components/DescribeInfra.vue';

    defineProps([
        "userInfo"
    ])

    let emits = defineEmits(["updateUserInfo"]);

    let infras = ref([]);
    let loaded = ref(false);

    let showAddInfra = ref(false);

    onMounted( () => {
        load();
    });

    function load(){
        loaded.value = false;
        axios.get("/api/users/me/infras")
            .then( answer => {
                infras.value = answer.data.infrasResponse;
                loaded.value = true;
            })
            .catch( e => {
                alert(e);
            })
    }

    // Close the AddInfra modal
    function closeInfraAdd(){
        showAddInfra.value = false;
        emits("updateUserInfo");
        load();
    }

    function removeDescribeInfraItem(item){
        infras.value.forEach( (infra,index) => {
            if(infra._id === item._id){
                infras.value.splice(index,1);
            }
        })
    }

</script>

<style>

</style>